import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Position,
  Dialog,
  Classes,
  InputGroup,
  Icon,
} from '@blueprintjs/core';
import { Edit, Add, Cross } from '@blueprintjs/icons';

export const CustomPropertyEditor = observer(({ store }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [customProperties, setCustomProperties] = React.useState([]);

  const hasSelection = store.selectedElements.length > 0;

  const handleOpen = () => {
    if (hasSelection) {
      const selectedElement = store.selectedElements[0];
      let customObj;
      if (typeof selectedElement.custom === 'string') {
        try {
          customObj = JSON.parse(selectedElement.custom);
        } catch {
          customObj = {};
        }
      } else {
        customObj = selectedElement.custom || {};
      }
      setCustomProperties(Object.entries(customObj).map(([key, value]) => ({ key, value })));
      setIsOpen(true);
    }
  };

  const handleClose = () => setIsOpen(false);

  const handleSave = () => {
    if (hasSelection) {
      const selectedElement = store.selectedElements[0];
      const customObj = Object.fromEntries(customProperties.map(prop => [prop.key, prop.value]));
      selectedElement.set({ custom: customObj });
      handleClose();
    }
  };

  const handleAddProperty = () => {
    setCustomProperties([...customProperties, { key: '', value: '' }]);
  };

  const handleRemoveProperty = (index) => {
    setCustomProperties(customProperties.filter((_, i) => i !== index));
  };

  const handlePropertyChange = (index, field, value) => {
    const updatedProperties = [...customProperties];
    updatedProperties[index][field] = value;
    setCustomProperties(updatedProperties);
  };

  return (
    <>
      <Button
        icon={<Edit />}
        minimal
        text="Edit Stags"
        onClick={handleOpen}
        disabled={!hasSelection}
      />

      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title="Edit Stags"
      >
        <div className={Classes.DIALOG_BODY}>
          {customProperties.map((prop, index) => (
            <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
              <InputGroup
                placeholder="Key"
                value={prop.key}
                onChange={(e) => handlePropertyChange(index, 'key', e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <InputGroup
                placeholder="Value"
                value={prop.value}
                onChange={(e) => handlePropertyChange(index, 'value', e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <Button icon={<Cross />} minimal onClick={() => handleRemoveProperty(index)} />
            </div>
          ))}
          <Button icon={<Add />} minimal onClick={handleAddProperty} text="Add Property" />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button intent="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});